import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link, useHistory } from "react-router-dom";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import FilterDate from "../../components/Form/FilterDate";
import AppTabs from "../../components/Tabs/AppTabs";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import StatusTransaction from "../../components/StatusTransaction";
import { Alert } from "@material-ui/lab";
import { Download } from "react-feather";
import useFetch from "../../hooks/useFetch";
import {
  ESparepartTransactionStatus,
  SparepartStatusLog,
  SparepartTransactionItem,
} from "../../types/fulfillment-sparepart.types";
import { apiProcessStatusSparepartTransaction } from "../../api/fulfillment-sparepart";
import { httpRequest } from "../../helpers/api/api";
import { format } from "date-fns";
import { generateQueryString } from "../../helpers/functions/generateQueryString";

let headersOrder: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Actions",
    align: "center",
    column: "actionsPaid",
  },
];

let headersProcess: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Actions",
    align: "center",
    column: "actionsWaybill",
  },
];

let headersSent: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Delivery Date",
    align: "left",
    column: "delivery_date",
  },
];

let headersCanceled: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Status",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Cancel Date",
    align: "left",
    sortable: true,
    column: "updatedAt",
    type: "date",
  },
  {
    title: "Remark",
    align: "left",
    column: "note",
  },
];

const tabs = ["Pemesanan", "Diproses", "Dikirim", "Dibatalkan"];

export default function FulfillmentSparepart() {
  const history = useHistory();

  const [actionType, setActionType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const [filterStatus, setFilterStatus] = useState("ASC");
  const [sort, setSort] = useState<any[]>([
    {
      column: "customName",
      sort: "ASC",
    },
  ]);

  const initialQuery = {
    limit: rowsPerPage,
    status: ESparepartTransactionStatus.CREATED,
  };

  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });

  const {
    data,
    loading,
    pagination,
    query,
    activeTab,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    setActiveTab,
  } = useFetch<SparepartTransactionItem>({
    url: "sparepart/transactions",
    query: initialQuery,
    activeTab: 0,
  });

  async function exportSparepart() {
    try {
      setLoadingExport(true);
      const res: any = await httpRequest.get(
        `/sparepart/transactions/export/xlsx${generateQueryString(query)}`,
        {
          responseType: "blob",
        }
      );

      console.log(JSON.stringify(res));

      if (res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const downloadDate = format(new Date(), "dd/MM/yyyy");
        link.setAttribute(
          "download",
          `oxone-fulfillment-sparepart-${downloadDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setLoadingExport(false);
      }
    } catch (error) {
      setLoadingExport(false);
    }
  }

  function handleSort(value: any) {
    setSort(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        orderBy:
          (value[0]?.sort || "ASC") === "ASC"
            ? "BUYER_NAME_ASC"
            : "BUYER_NAME_DESC",
      };
    });
  }

  const changeTab = (value: number) => {
    // setQuery(latestParams.query)
    setActiveTab(value);
    if (value === 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          status: ESparepartTransactionStatus.CREATED,
        };
      });
    }
    if (value === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          status: ESparepartTransactionStatus.PROCESS,
        };
      });
    }
    if (value === 2) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          status: ESparepartTransactionStatus.SENT,
        };
      });
    }
    if (value === 3) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          status: ESparepartTransactionStatus.CANCELED,
        };
      });
    }
  };

  function setFilter(sort: "ASC" | "DESC") {
    setFilterStatus(sort);
    if (sort === "ASC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "CREATED_AT_ASC",
        };
      });
    } else if (sort === "DESC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "CREATED_AT_DESC",
        };
      });
    }
  }

  const sparepartTransactions = data.map((item: SparepartTransactionItem) => {
    return {
      ...item,
      delivery_date: item.status_logs.find((log) => log.statusAfter === "sent")
        ? convertDateTimeToText(
            (item.status_logs.find((log) => log.statusAfter === "sent") as any)
              .createdAt
          )
        : null,
      customName: (
        <div>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {item.transactionMetadata && item.transactionMetadata.buyer
              ? item.transactionMetadata.buyer.name
              : "---"}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" style={{ width: 90 }}>
              No. Transaksi
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              style={{ fontWeight: "bold" }}
            >
              : {item?.invoiceId || item.transactionId}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" style={{ width: 90 }}>
              Tgl. Pembelian
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              style={{ fontWeight: "bold" }}
            >
              : {convertDateTimeToText(item.createdAt, "dd/mm/yy")}
            </Typography>
          </div>
        </div>
      ),
      order: (
        <Box display="flex" flexDirection="row">
          {/* <Box marginRight={1}>
             {item.items &&
            item.items.length > 0 &&
            item.items[0].productMetadata.images &&
            item.items[0].productMetadata.images.length > 0 ? (
              <CardMedia className={classes.cardMedia} image={item.items[0].productMetadata.images[0]} />
            ) : (
            <CardMedia className={classes.cardMedia} image={"/assets/default_500x500.png"} />
              )} 
          </Box> */}
          <Box>
            <Typography variant="body1">
              {item.items &&
                item.items.length > 0 &&
                (item.items[0].sparepartMetadata.productCode
                  ? item.items[0].sparepartMetadata.productCode + " "
                  : "") + item.items[0].sparepartMetadata.sparepartName}
            </Typography>

            {item.items.length - 1 > 0 && (
              <Typography variant="subtitle1">
                + {item.items.length - 1} barang lainnya
              </Typography>
            )}

            <Link
              style={{
                textDecoration: "none",
                borderBottom: "2px dotted rgb(169 169 169)",
                display: "inline-block",
              }}
              to={"/fulfillment-sparepart/" + item.transactionId}
            >
              <Typography variant="subtitle1" style={{ color: COLORS.primary }}>
                Lihat Detail Pesanan
              </Typography>
            </Link>
          </Box>
        </Box>
      ),
      process: (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <StatusTransaction status={item.status} isFulfillmentSparepart />
        </Box>
      ),
      shipment: (
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" style={{ width: 60 }}>
              Penerima
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              style={{ fontWeight: "bold" }}
            >
              :{" "}
              {item.transactionMetadata.buyer.name
                ? item.transactionMetadata.buyer.name
                : "---"}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Typography
              variant="subtitle1"
              component="span"
              style={{ fontWeight: "bold", width: 200 }}
            >
              {item?.transactionMetadata?.buyer?.serviceCenter?.metaAddress
                ?.address || "---"}
              ,{" "}
              {item?.transactionMetadata?.buyer?.serviceCenter?.metaAddress
                .subdistrict || "---"}
              ,{" "}
              {item?.transactionMetadata?.buyer?.serviceCenter?.metaAddress
                ?.city || "---"}
              ,{" "}
              {item?.transactionMetadata?.buyer?.serviceCenter?.metaAddress
                ?.province || "---"}
            </Typography>
          </div>
        </Box>
      ),
      actionsPaid: (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Box marginBottom={1}>
            <AppButton
              size="small"
              color="primary"
              onClick={async () => {
                setActionType("process");
                await apiProcessStatusSparepartTransaction(item.transactionId, {
                  newStatus: ESparepartTransactionStatus.PROCESS,
                });
                setActionType("");
                setQuery((val: any) => {
                  return {
                    ...val,
                  };
                });
              }}
            >
              Proses Pesanan
            </AppButton>
          </Box>
        </Box>
      ),
      actionsWaybill: (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Box marginBottom={1}>
            <AppButton
              size="small"
              color="primary"
              onClick={async () => {
                setActionType("sent");
                await apiProcessStatusSparepartTransaction(item.transactionId, {
                  newStatus: ESparepartTransactionStatus.SENT,
                });
                setActionType("");
                setQuery((val: any) => {
                  return {
                    ...val,
                  };
                });
              }}
            >
              Dikirim
            </AppButton>
          </Box>
        </Box>
      ),
      // actionsAll: renderActionAll(item),
      // actionsAllPackaging: renderHeadersAllPackaging(item),
      note: renderNote(item.status_logs),
    };
  });

  function renderNote(data: SparepartStatusLog[]) {
    if (Array.isArray(data) && data.length > 0) {
      const found = data.find(
        (item: SparepartStatusLog) =>
          item.statusAfter === ESparepartTransactionStatus.CANCELED
      ); //rejected
      if (found) {
        return found.remark;
      }
    }
    return "";
  }

  return (
    <Layout title="Transaction" loading={loading}>
      <HeaderPage title="Transaction" subtitle="List of all Transaction" />

      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={12} lg={1}></Grid>
        <Grid item xs={12} md={1} lg={1}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-status" shrink>
              Sort
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value="ASC">ASC</MenuItem>
              <MenuItem value="DESC">DESC</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Search
            placeholder="Search by Name, Transaction ID"
            onChange={(value: string) => changeHandler(value)}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }
          />
        </Grid>

        <Grid style={{ marginRight: 30 }} item xs={12} md={6} lg={1}>
          <AppButton
            onClick={() => {
              exportSparepart();
            }}
            loading={loadingExport}
            size="small"
            color="primary"
          >
            <Download size={18} />
            <Typography
              component="span"
              style={{ fontSize: 12, marginLeft: 5 }}
            >
              Export
            </Typography>
          </AppButton>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              changeTab(value);
            }}
          />

          {activeTab === 0 && (
            <CustomizedTable
              data={sparepartTransactions}
              headers={headersOrder}
              totalData={data?.length}
              page={pagination.page}
              totalPage={pagination.countPage}
              rowsPerPage={rowsPerPage}
              sort={sort}
              onChangeSort={(value) => handleSort(value)}
              onChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          )}

          {activeTab === 1 && (
            <CustomizedTable
              data={sparepartTransactions}
              headers={headersProcess}
              totalData={data?.length}
              page={pagination.page}
              totalPage={pagination.countPage}
              rowsPerPage={rowsPerPage}
              sort={sort}
              onChangeSort={(value) => handleSort(value)}
              onChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          )}

          {activeTab === 2 && (
            <CustomizedTable
              data={sparepartTransactions}
              headers={headersSent}
              totalData={data?.length}
              page={pagination.page}
              totalPage={pagination.countPage}
              rowsPerPage={rowsPerPage}
              sort={sort}
              onChangeSort={(value) => handleSort(value)}
              onChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          )}

          {activeTab === 3 && (
            <CustomizedTable
              data={sparepartTransactions}
              headers={headersCanceled}
              totalData={data?.length}
              page={pagination.page}
              totalPage={pagination.countPage}
              rowsPerPage={rowsPerPage}
              sort={sort}
              onChangeSort={(value) => handleSort(value)}
              onChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          )}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={actionType !== ""}
        autoHideDuration={6000}
      >
        <Alert severity="success" variant="filled">
          Berhasil{" "}
          {actionType === "process"
            ? "memproses pesanan"
            : actionType === "reject"
            ? "membatalkan pesanan"
            : actionType === "waybill"
            ? "memasukkan nomor resi"
            : ""}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    rejectContent: {
      width: "400px",
      display: "grid",
      gridTemplateColumns: "140px 1fr",
      marginTop: 10,
      marginBottom: 10,
    },
  })
);
